@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next {
  @apply bg-black;
  @apply text-white/70;
}

html {
  scroll-behavior: smooth;
}

#nprogress .bar {
  @apply bg-brand-500;
}

#nprogress .spinner-icon {
  @apply border-t-brand-500;
  @apply border-l-brand-500;
}

/* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  hyphens: auto;

}

.notion-quote:before {
  position: absolute;
  font-family: emoji;
  font-size: 4em;
  line-height: 1;
  content: "“";
  left: -56px;
  top: 0;
}

.notion-h[data-active="true"] {
  position: sticky;
  top: -1px;
  background: black;
  z-index: 10;
  padding-top: 6px;
  padding-bottom: 6px;
}

@media (min-width: 768px) {
  .notion-h[data-active="true"] {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}